import { createTemperatureValue } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThermostatActivityById } from '../actions/controls';
import { ApplicationState } from '../reducers';
import { ThermostatActivityDataStatus } from '../reducers/types/controlsReducer';
import useCurrentUser from './useCurrentUser';
import { useTimeFilter } from './useFilters';
import { useTemperatureUnit } from './utils';
import { pathOr } from 'ramda';

/**
 *
 * @param thermostatId
 * @param featureNotificationId notification or incident
 * @param customFromDate custom date for charts independent from global time filter
 * @param customToDate custom date for charts independent from global time filter
 */
const useThermostatActivity = (
  thermostatId: string | number | undefined,
  featureNotificationId?: string,
  customFromDate?: Date,
  customToDate?: Date
): ThermostatActivityDataStatus => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const { timePeriod } = useTimeFilter();
  const temperatureUnit = useTemperatureUnit();
  const { fromDate, toDate } = timePeriod;

  useEffect(() => {
    if (thermostatId) {
      dispatch(
        getThermostatActivityById(
          thermostatId,
          customFromDate || fromDate,
          customToDate || toDate,
          featureNotificationId
        )
      );
    }
  }, [
    dispatch,
    featureNotificationId,
    customFromDate,
    customToDate,
    fromDate,
    toDate,
    thermostatId,
  ]);

  const thermostatActivityAndLoadingStatus = useSelector<
    ApplicationState,
    ThermostatActivityDataStatus
  >(({ controls }) => {
    const dataStatus = featureNotificationId
      ? pathOr(
          undefined,
          [featureNotificationId],
          controls.thermostatActivityByFeatureNotificationId
        )
      : pathOr(undefined, [thermostatId], controls.thermostatActivityById);

    if (dataStatus === undefined) {
      return {
        isLoading: false,
      };
    }

    if (dataStatus.data && currentUser) {
      const convertedData = dataStatus.data.map((dataPoint) => {
        return {
          ...dataPoint,
          coolSetPoint: createTemperatureValue(
            dataPoint.coolSetPoint,
            currentUser,
            0,
            true
          ),
          heatSetPoint: createTemperatureValue(
            dataPoint.heatSetPoint,
            currentUser,
            0,
            true
          ),
          temperatureInC: dataPoint.temperature,
          temperature: createTemperatureValue(
            dataPoint.temperature,
            currentUser,
            1,
            true
          ),
          temperatureUnit,
        };
      });

      return {
        isLoading: dataStatus.isLoading,
        data: convertedData,
      };
    }
    return dataStatus;
  }, equals);

  return thermostatActivityAndLoadingStatus;
};

export default useThermostatActivity;
