import { ThermostatActivity } from '@energybox/react-ui-library/dist/types';
import { DefaultChartStyles } from '@energybox/react-ui-library/dist/utils';
import { parseISO } from 'date-fns';
import React from 'react';
import {
  Area,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useTimeFilter } from '../../hooks/useFilters';
import styles from './ThermostatBarChart.module.css';
import { getVenstarEdgeApp2dot4DeploymentDate } from '../../config';
import { skipDataBasedOnTime } from '../../util';
import { Loader } from '@energybox/react-ui-library/dist/components';

type HvacEventType =
  | 'coolingStage1Running'
  | 'coolingStage2Running'
  | 'heatingStage1Running'
  | 'heatingStage2Running'
  | 'fanRunning'
  | 'blank';

interface HvacEvent {
  start: number;
  end: number;
  type: HvacEventType;
  key?: string;
}

type HvacEvents = HvacEvent[];

interface Props {
  activityData: ThermostatActivity[];
  chartMouseoverTime: number;
  customFromDate?: number;
  customToDate?: number;
  isVenstarHeatpump?: boolean;
  dataLoadCompleted?: boolean;
}

const chartColorDict = {
  blank: '#fff',
  coolingStage1Running: 'var(--blue-basePlus75)',
  coolingStage2Running: 'var(--blue-basePlus25)',
  heatingStage1Running: 'var(--orange-basePlus75)',
  heatingStage2Running: 'var(--orange-basePlus25)',
  fanRunning: 'var(--sun-basePlus50)',
};

const legendPayload = [
  {
    color: chartColorDict.coolingStage1Running,
    type: 'rect',
    value: 'Cooling Stage 1',
  },
  {
    color: chartColorDict.coolingStage2Running,
    type: 'rect',
    value: 'Cooling Stage 2',
  },
  {
    color: chartColorDict.heatingStage1Running,
    type: 'rect',
    value: 'Heating Stage 1',
  },
  {
    color: chartColorDict.heatingStage2Running,
    type: 'rect',
    value: 'Heating Stage 2',
  },
  {
    color: chartColorDict.fanRunning,
    type: 'rect',
    value: 'Fan',
  },
];

const ThermostatBarChart: React.FC<Props> = ({
  activityData,
  chartMouseoverTime,
  customFromDate,
  customToDate,
  isVenstarHeatpump = false,
  dataLoadCompleted,
}) => {
  const { timePeriod } = useTimeFilter();

  const { coolingEvents, heatingEvents, fanEvents } = parseActivityData(
    activityData,
    isVenstarHeatpump
  );

  const dataForAreaChart = generateChartDataFromEvents(
    coolingEvents,
    heatingEvents,
    fanEvents
  );

  return (
    <div className={styles.root}>
      {dataLoadCompleted && (
        <div className={styles.loading}>
          <span>
            <Loader size={40} />{' '}
          </span>
          <h6>Data is loading...</h6>
        </div>
      )}
      <ResponsiveContainer height={160}>
        <ComposedChart
          data={dataForAreaChart}
          margin={{
            top: 20,
            bottom: 20,
          }}
        >
          {[0, 10, 20, 30].map((yValue) => (
            <ReferenceLine
              y={yValue}
              stroke={DefaultChartStyles.axisLineColor}
            />
          ))}
          <ReferenceLine x={chartMouseoverTime} />
          <XAxis
            dataKey="time"
            type="number"
            domain={[
              customFromDate || timePeriod.fromDate.valueOf(),
              customToDate || timePeriod.toDate.valueOf(),
            ]}
            hide
          />
          <YAxis
            //not sure why this width is 27 as opposed to default 20
            //but we need this value to match with other charts
            width={DefaultChartStyles.yAxisWidth + 7}
            axisLine={false}
            tickLine={false}
            domain={[0, 30]}
            ticks={[5, 15, 25]}
            tick={DefaultChartStyles.tick}
            tickFormatter={formatYAxisTicks}
          />
          {dataForAreaChart.map(createHvacAreaComponent)}
          <Legend
            payload={legendPayload}
            wrapperStyle={DefaultChartStyles.legendContainer}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const createHvacAreaComponent = (
  dataItem: [AreaChartDataPoint, AreaChartDataPoint]
) => {
  const color = chartColorDict[dataItem[0].type];
  return (
    <Area
      dataKey="yRange"
      data={dataItem}
      stroke={color}
      fill={color}
      fillOpacity="1"
      activeDot={false}
      isAnimationActive={false}
    />
  );
};

const formatYAxisTicks = (value: number) => {
  const ticksToTitleMap = {
    5: 'Fan',
    15: 'Heating',
    25: 'Cooling',
  };

  return ticksToTitleMap[value];
};

const parseActivityData = (
  activityData: ThermostatActivity[],
  isVenstarHeatpump: boolean
): {
  coolingEvents: HvacEvents;
  heatingEvents: HvacEvents;
  fanEvents: HvacEvents;
} => {
  const getDataByType = (type: HvacEventType) =>
    getDataByHvacEventType(activityData, type, isVenstarHeatpump);
  const cooling1Times = getDataByType('coolingStage1Running');
  const cooling2Times = getDataByType('coolingStage2Running');
  const heating1Times = getDataByType('heatingStage1Running');
  const heating2Times = getDataByType('heatingStage2Running');
  const fanTimes = getDataByType('fanRunning');
  const mergedCoolingTimes = mergeStage1Stage2Data(
    cooling1Times,
    cooling2Times,
    'cooling'
  );
  const mergedHeatingTimes = mergeStage1Stage2Data(
    heating1Times,
    heating2Times,
    'heating'
  );

  return {
    coolingEvents: mergedCoolingTimes,
    heatingEvents: mergedHeatingTimes,
    fanEvents: fanTimes,
  };
};

interface AreaChartDataPoint {
  time: number;
  type: HvacEventType;
  yRange: number[];
}

const generateChartDataFromEvents = (
  coolingEvents: HvacEvents,
  heatingEvents: HvacEvents,
  fanEvents: HvacEvents
): [AreaChartDataPoint, AreaChartDataPoint][] => {
  const dataRangeForCooling = [20, 30];
  const dataRangeForHeating = [10, 20];
  const dataRangeForFan = [0, 10];
  const generateData = (events: HvacEvents, range: number[]) => {
    const dataForAreaChart: [AreaChartDataPoint, AreaChartDataPoint][] = [];

    events.forEach(({ start, end, type }) => {
      dataForAreaChart.push([
        {
          time: start,
          type,
          yRange: range,
        },
        {
          time: end,
          type,
          yRange: range,
        },
      ]);
    });
    return dataForAreaChart;
  };
  return [
    ...generateData(coolingEvents, dataRangeForCooling),
    ...generateData(heatingEvents, dataRangeForHeating),
    ...generateData(fanEvents, dataRangeForFan),
  ];
};

/**
 *
 * @param thermostatActivityData
 * @param key
 * @returns events in chronological order for specific hvac function and stage
 */
const getDataByHvacEventType = (
  thermostatActivityData: ThermostatActivity[],
  key: HvacEventType,
  isVenstarHeatpump: boolean
): HvacEvents => {
  let startOfPeriod: string | undefined;
  const result: HvacEvents = [];
  const edgeApp2dot4Date = getVenstarEdgeApp2dot4DeploymentDate();
  thermostatActivityData.forEach((thermostatData, index) => {
    if (
      isVenstarHeatpump &&
      skipDataBasedOnTime(thermostatData.time, edgeApp2dot4Date)
    ) {
      return;
    }
    if (thermostatData[key]) {
      if (startOfPeriod === undefined) {
        startOfPeriod = thermostatData.time;
      } else if (
        startOfPeriod !== undefined &&
        index + 1 === thermostatActivityData.length
      ) {
        result.push({
          start: parseISO(startOfPeriod).valueOf(),
          end: parseISO(thermostatData.time).valueOf(),
          type: key,
        });
      } else {
        //Adding breaks of idle times on control details with a timeframe of 90min based on thermostat graph
        const currentIndexTime = thermostatActivityData[index]?.timestamp;
        const nextIndexTime = thermostatActivityData[index + 1]?.timestamp;
        if (
          new Date(currentIndexTime).getDate() !==
            new Date(nextIndexTime).getDate() ||
          Math.floor((nextIndexTime - currentIndexTime) / 60 / 1000) >= 90
        ) {
          result.push({
            start: parseISO(startOfPeriod).valueOf(),
            end: parseISO(thermostatData.time).valueOf(),
            type: key,
          });
          startOfPeriod = thermostatActivityData[index + 1].time;
        }
      }
    } else {
      if (
        startOfPeriod !== undefined &&
        (index + 1 === thermostatActivityData.length ||
          (index + 1 < thermostatActivityData.length &&
            thermostatActivityData[index + 1][key] === false))
      ) {
        result.push({
          start: parseISO(startOfPeriod).valueOf(),
          end: parseISO(thermostatData.time).valueOf(),
          type: key,
        });
        // calculate time from start to now
        startOfPeriod = undefined;
      }
    }
  });

  return result;
};

const mergeStage1Stage2Data = (
  stage1Times: HvacEvents,
  stage2Times: HvacEvents,
  type: 'cooling' | 'heating'
) => {
  const mergedData: HvacEvents = [];
  const typeStage1 =
    type === 'cooling' ? 'coolingStage1Running' : 'heatingStage1Running';
  const typeStage2 =
    type === 'cooling' ? 'coolingStage2Running' : 'heatingStage2Running';
  const alreadyMergedStage1Events = new Set<string>();

  if (stage1Times.length === 0 && stage2Times.length > 0) {
    stage2Times.forEach(({ start, end }) => {
      mergedData.push({
        start,

        end,

        type: typeStage2,
      });
    });
  } else if (stage1Times.length > 0 && stage2Times.length === 0) {
    stage1Times.forEach(({ start, end }) => {
      mergedData.push({
        start,

        end,

        type: typeStage1,
      });
    });
  } else {
    stage2Times.forEach(({ start: start2, end: end2 }) => {
      stage1Times.forEach(({ start: start1, end: end1 }) => {
        if (start2 === start1 && end2 === end1) {
          // Stage 1 stays set as true when stage 2 is running, so if both stages
          // have an identical section than just show stage 2
          alreadyMergedStage1Events.add(`${start1}${end1}`);
          mergedData.push({
            start: start2,
            end: end2,
            type: typeStage2,
          });
        } else if (start2 === start1) {
          // If they start at the same time, but don't finish together than stage
          // 1 should continue after stage 2
          alreadyMergedStage1Events.add(`${start1}${end1}`);
          mergedData.push({
            start: start2,
            end: end2,
            type: typeStage2,
          });
          mergedData.push({
            start: end2 + 1,
            end: end1,
            type: typeStage1,
          });
        } else if (start2 > start1 && end2 === end1) {
          // If stage 2 starts after 1, but they share the same end then stage 1
          // should display until stage 2 starts
          alreadyMergedStage1Events.add(`${start1}${end1}`);
          mergedData.push({
            start: start1,
            end: start2 - 1,
            type: typeStage1,
          });
          mergedData.push({
            start: start2,
            end: end2,
            type: typeStage2,
          });
        } else if (start2 > start1 && end2 < end1) {
          // If stage 2 is in the middle of stage 1 running
          alreadyMergedStage1Events.add(`${start1}${end1}`);
          mergedData.push({
            start: start1,
            end: start2 - 1,
            type: typeStage1,
          });
          mergedData.push({
            start: start2,
            end: end2,
            type: typeStage2,
          });
          mergedData.push({
            start: end2 + 1,
            end: end1,
            type: typeStage1,
          });
        }
      });
    });
    // Stage 2 is looped over first, so any stage 1 events that weren't
    // overlapping and yet to happen never got added to the merged event list
    stage1Times.forEach((event) => {
      const { start, end } = event;
      if (!alreadyMergedStage1Events.has(`${start}${end}`)) {
        mergedData.push(event);
      }
    });

    // in the case stage 2 is missing in the main loop.
    stage2Times.forEach((event) => {
      const { start, end } = event;
      if (!alreadyMergedStage1Events.has(`${start}${end}`)) {
        mergedData.push(event);
      }
    });
  }

  // sort mergedData by startDate, smallest first
  const sorted = mergedData.sort(sortByStartingTime);
  return sorted;
};

const sortByStartingTime = (a: HvacEvent, b: HvacEvent) => a.start - b.start;

export default ThermostatBarChart;
